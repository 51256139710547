<template>
    <div class="page">
        <app-header :navIndex="4"></app-header>
        <banner :data="banner"></banner>
        <section class="top-box">
            <div class="top-border hidden-md-and-up white"></div>
            <div class="desc pa-5 pa-md-0 section-container mt-md-12" v-html="banner.desc"></div>
            <section class="section-container mt-md-12 mb-12 " v-for="(item,index) in primeurList" :key="index">
                <section-title :title="item.title" :enTitle="item.enTitle"></section-title>
                <div class="d-flex flex-column mt-md-7"
                     :class="`${index==0?'flex-md-row justify-space-between':''}${item.title?'':'pt-md-12 pb-md-12'}`">
                    <div class="desc order-0 pa-5 pa-md-0"
                         :class="index==0?'col-md-6':''" v-html="item.desc">
                    </div>
                    <div class="pa-0" :class="index==0?'order-md-first order-1 col-md-5':'order-1 mt-md-7'">
                        <v-img :src="item.img"></v-img>
                    </div>
                </div>
            </section>
        </section>

        <section class="section-container mb-8 mb-md-0">
            <div class="other-title font-italic font-weight-bold mt-md-12 mb-md-10 pa-5 pa-md-0">{{auction.tip}}</div>
            <section-title :title="auction.title" :enTitle="auction.enTitle"></section-title>
            <div class="desc mt-md-12 mb-md-10 pa-5 pa-md-0" v-html="auction.desc"></div>
            <v-img :src="auction.img"></v-img>
        </section>

        <section class="d-flex flex-column flex-md-row section-container mt-md-12 mb-md-12">
            <v-hover v-slot:default="{hover}" class="col-md-4 ma-md-1" v-for="(item,index) in auctionList">
                <v-card class="inner-box" :elevation="hover ? 6 : 0"
                        :class="{ 'on-hover': hover }">
                    <div class="inner-title text-center font-weight-bold">{{item.title}}</div>
                    <div class="sub-title text-center pt-3 pb-5">{{item.subTitle}}</div>
                    <div class="desc">{{item.desc}}</div>
                </v-card>
            </v-hover>
        </section>

        <!--<div class="desc section-container mt-5 mt-md-0 mb-12 pb-12 pa-5">{{auctionDesc}}</div>-->
    </div>
</template>
<script>
    import Banner from "../../components/base/banner";
    import AppHeader from "../../components/core/header/index";
    import SectionTitle from "../company/components/section-title";
    export default {
        components: {
            SectionTitle,
            AppHeader,
            Banner
        },
        data(){
            return {
                banner: {
                    imgUrl: 'product/gcc/bg.jpg',
                    title: this.$i18n.t('primeur.finewine.title'),
                    enTitle: '',
                    desc: this.$i18n.t('primeur.finewine.desc'),
                },
                primeurList: this.$i18n.t('primeur.list'),
                auction: {
//                    img: ('https://website-asc.oss-cn-shanghai.aliyuncs.com/services/auction/img.jpg'),
                    img: 'https://website-asc.oss-cn-shanghai.aliyuncs.com/services/auction/img.png',
                    title: this.$i18n.t('auction.auction.title'),
                    enTitle: this.$i18n.t('auction.auction.enTitle'),
                    desc: this.$i18n.t('auction.auction.desc'),
                    tip: this.$i18n.t('auction.auction.tip'),
                },
                auctionList: this.$i18n.t('auction.list'),
//                auctionDesc: this.$i18n.t('auction.desc'),
            }
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "精品葡萄酒服务"
            document.getElementById("bodyId").appendChild(div);
            var box = document.getElementsByClassName('inner-box')
            var cardHeight = 0
            this.auctionList.forEach((item, index) => {
                if (cardHeight < box[index].offsetHeight)
                    cardHeight = box[index].offsetHeight
            })
            this.auctionList.forEach((item, index) => {
                box[index].style.height = cardHeight + 'px'
            })
        }
    }
</script>
<style lang="scss" scoped>
    .other-title {
        font-size: 26px;
    }

    .inner-box {
        /*height: 470px;*/
        padding: 60px 40px 60px;
        .inner-title {
            font-size: 18px;

        }
        .sub-title {
            font-size: 16px;
            color: $primary-color;
        }
        .desc {
            font-size: 14px;
            line-height: 30px;
        }
    }

    .on-hover {
        background-color: $primary-color;
        .inner-title, .sub-title, .desc {
            color: white;
        }
    }

    @media (max-width: $screen-md) {
        .other-title {
            font-size: 16px;
        }
        .inner-box {
            padding: 40px 20px;
            height: auto;
            .inner-title {
                font-size: 16px;

            }
            .sub-title {
                font-size: 14px;
            }
            .desc {
                font-size: 12px;
            }
        }
    }
</style>
